import React, { useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReactGA from 'react-ga';

import headerImg from '../../assets/homepage/header.jpg'
import logo from '../../assets/kpsLogo.png'
import me from '../../assets/homepage/me.jpg'
import config from '../../config.json';

ReactGA.initialize(config.googleTrackingID);

const useStyles = makeStyles(theme => ({
    imageBackground:{
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%'
    },
    test: {
        padding: '20em',
    },
    logo:{
        height: '25em',
        padding: '2em',
        [theme.breakpoints.down('xs')]:{
            height: '20em',
            padding: '1.5em'
        }
    },
    tagline: {
        marginBottom: '1.5em',
        color: 'white'
    },
    green: {
        color: theme.palette.common.screamingGreen
    },
    me:{
        border: 'solid 15px rgba(62, 68, 77, 0.5)',
        borderRadius: '50%',
        width: '30em',
        [theme.breakpoints.down('md')]:{
            width: '15em',
        }
    },
    statsCircle:{
        height: '2.5em',
        width: '2.5em',
        fontSize: '4em',
        fontWeight: 150,
        [theme.breakpoints.down('md')]: {
            fontSize: '3em'
        }
    },
    root: {
        width: 300,
        height: 350,
        [theme.breakpoints.down('md')]: {
            width: 300,
            height: 300,
        }
    },
    media: {
        height: 140,
    },
    cardDate: {
        fontSize: '1.5em'
    }
}))

const Homepage = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [books, setBooks] = useState(0)
    const [bucketlist, setBucketlist] = useState(0)
    const [countries, setCountries] = useState(0)
    const [films, setFilms] = useState(0)
    const [blogPosts, setBlogPosts] = useState([])

    const matchesXS =  useMediaQuery(theme.breakpoints.down('xs'))
    const matchesMD =  useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        async function fetchUrl(url) {
            ReactGA.pageview(window.location.pathname + window.location.search);
            // Set Books Read
            let response = await fetch('api/blog/posts?category=4')
            let json = await response.json()
            setBooks(json.length)

            // Set Bucketlist
            response = await fetch('api/bucketlist/percentage')
            json = await response.json()
            setBucketlist(json.percentage)

            // Set Countries Visited
            response = await fetch('api/scratchmap/visited')
            json = await response.json()
            setCountries(json.count)

            // Set Films Watched
            response = await fetch('api/film/count')
            json = await response.json()
            setFilms(json.count)

            // Set Blog Posts
            response = await fetch('api/blog/latest')
            json = await response.json()
            setBlogPosts(json)
        }

        ReactGA.pageview(window.location.pathname + window.location.search)
        fetchUrl()
        
    }, [])

    return (
        <Grid container>
            {/* ----- Header Block ----- */}
            <Grid item container className={classes.imageBackground}>
                <Grid container direction='column' justify='center' alignItems="center" styles={{padding: '5em', marginBottom: matchesXS ? '10em': 0}}>
                    <Grid item>
                        <img alt='KPS logo' src={logo} className={classes.logo} style={{marginTop: '5em'}}/>
                    </Grid>
                    <Grid item>
                        <Typography variant='h3' className={classes.tagline}>Tech | <span className={classes.green}>Sport</span> | Life</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- About Block ----- */}
            <Grid item container direction={ matchesMD ? 'column' : 'row'} style={{padding : '2em'}} alignItems='center' justify={ matchesMD ? 'center' : 'space-between'}>
                <Grid item style={{marginLeft: matchesMD ? 0 : '5em'}}>
                    <img src={me} className={classes.me} alt="Khrishan Patel" />
                </Grid>
                <Grid item container direction='column' alignItems={ matchesMD ? 'center' : 'flex-end'} style={{paddingRight: matchesMD ? 0 : '5em', maxWidth: '40em'}}>
                    <Grid item style={{paddingTop: matchesMD ? '2em' : 0 }}>
                        <Typography variant='h2'>About</Typography>
                    </Grid>
                    <Grid item style={{paddingTop: matchesMD ? '2em' : 0 }}>
                        <Typography variant='body1' align={ matchesMD ? 'center' : 'right'} paragraph gutterBottom>I'm Khrishan Patel. I'm a tech enthusiast, who loves (nearly) every sport on the planet!</Typography>
                    </Grid>
                    <Grid item>
                        <Typography  variant='body1' align={ matchesMD ? 'center' : 'right'} paragraph gutterBottom>This is my personal highlights reel, where I voice my opinion and showcase my work.</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- Stats Block ----- */}
            <Grid container direction='column'>
                <Grid item style={{padding: matchesMD ? '1.5em' : '5em'}}>
                    <Typography variant='h2' align='center'>Latest Stats</Typography>
                </Grid>
                <Grid item>
                    <Grid container direction={matchesXS ? 'column' : 'row'} justify='space-evenly' alignItems='center' style={{padding: '2.5em', paddingBottom: '10em'}}>
                        <Grid item>
                            <Grid item container direction='column' lg alignItems='center'>
                                <Grid item>
                                    <Typography variant='h4'>Books Read</Typography>
                                </Grid>
                                <Grid item style={{padding: '2em'}}>
                                    <Avatar className={classes.statsCircle} variant='circle'>{books}</Avatar>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item container direction='column' lg alignItems='center'>
                                <Grid item>
                                    <Typography variant='h4'>Bucketlist Complete</Typography>
                                </Grid>
                                <Grid item style={{padding: '2em'}}>
                                    <Avatar className={classes.statsCircle} variant='circle'>{bucketlist}%</Avatar>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item container direction='column' lg alignItems='center'>
                                <Grid item>
                                    <Typography variant='h4'>Countries Visited</Typography>
                                </Grid>
                                <Grid item style={{padding: '2em'}}>
                                    <Avatar className={classes.statsCircle} variant='circle'>{countries}</Avatar>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item container direction='column' lg alignItems='center'>
                                <Grid item>
                                    <Typography variant='h4'>Films Watched</Typography>
                                </Grid>
                                <Grid item style={{padding: '2em'}}>
                                    <Avatar className={classes.statsCircle} variant='circle'>{films}</Avatar>
                                </Grid>
                            </Grid>
                        </Grid>    
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- Blog Posts Block ----- */}
            <Grid container direction='column' style={{backgroundColor: theme.palette.common.screamingGreen }}>
                <Grid item style={{padding: matchesMD ? '2em' : '5em'}}>
                    <Typography variant='h2' align='center'>Latest Blog Posts</Typography>
                </Grid>
                <Grid item>
                    <Grid container direction='row' justify='space-around' style={{paddingBottom: '10em'}}>
                        {blogPosts.map((post, index) => (
                            <Grid item style={{ padding : '1em'}}>
                                <Grid item container alignItems='center' justify='center' onClick={() => window.open('/blog/' + post.url, '_self')} style={{ backgroundImage :  `url("/api/public/blog/${post.id}_${post.url}/img/header.jpg")`, height : '300px', width : '300px', backgroundSize: 'cover', cursor : 'pointer' }}>  
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Homepage
