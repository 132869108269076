import React, { useState, useEffect} from 'react'
import ReactGA from 'react-ga';
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ImageGallery from 'react-image-gallery'
import marked from 'marked'

import config from '../../config.json';

import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css"

const useStyles = makeStyles(theme => ({
    imageBackground:{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        paddingTop: '5em'
    },
    title: {
        padding: '0.5em',
        backgroundColor: '#000000',
    },
    dateTitle:{
        backgroundColor: '#000000',
        padding: '0.25em'
    },
    blogPost: {
        ...theme.typography.body1,
        padding: '2em'
    }
}))

ReactGA.initialize(config.googleTrackingID);

const BlogPost = () => {
    let { slug } = useParams()

    const [blogInfo, setBlogInfo] = useState({})
    const [blogPost, setBlogPost] = useState('')
    const [isGallery, setIsGallery] = useState(false)
    const [galleryData, setGalleryData] = useState([])

    const classes = useStyles()

    useEffect(() => {
        async function fetchUrl(slug) {
            const is_num = /^\d+$/.test(slug)
            let json = {}
            let response = ''
            let url = ''

            if (is_num){
                url = '/api/blog/post?id=' + Number.parseInt(slug)
                response = await fetch(url)
                json = await response.json()
                slug = json.url
            } else {
                url = '/api/blog/post?url=' + slug
                response = await fetch(url)
                json = await response.json()
            }

            setBlogInfo(json)

            url = `/api/public/blog/${json.id}_${slug}/post.md`
            response = await fetch(url)
            let text = await response.text()
            setBlogPost(text)

            if(json.gallery){
                url = `/api/public/blog/${json.id}_${slug}/img/data.json`
                response = await fetch(url)
                setIsGallery(true)
                text = await response.text()
                let obj = JSON.parse(text)
                setGalleryData(obj)
            } else {
                setIsGallery(false)
                setGalleryData([])
            }
        }

        fetchUrl(slug)
        ReactGA.pageview(window.location.pathname + window.location.search);
        
    }, [slug])

    return (
        <Grid>
            {/* ----- Header Block ----- */}
            <Grid item container className={classes.imageBackground} style={{backgroundImage: `url('/api/public/blog/${blogInfo.id}_${blogInfo.url}/img/header.jpg')`,}}>
                <Grid container direction='column' justify='center' alignItems="center">
                    <Grid item style={{ paddingTop : '5em' }} >
                        <Typography variant='h1' align='center' className={classes.title}>{ blogInfo.title }</Typography>
                    </Grid>
                    <Grid item  style={{ paddingTop : '2em', paddingBottom : '5em' }} >
                        <Typography className={classes.dateTitle} align='center' variant='h5'>{ blogInfo.created_date }</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- Blog Post Block ----- */}
            <Grid item container className={classes.blogPost}>
                <Grid item>
                    <div dangerouslySetInnerHTML={ { __html: marked(blogPost, {sanitize: false} ) } } />
                </Grid>
                <Grid item>
                    <div style={{display: isGallery ? 'block' : 'none'}}>
                        <ImageGallery items={galleryData} />
                    </div>
                </Grid>            
            </Grid>
        </Grid>
    )
}

export default BlogPost
