
import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { CookiesProvider } from 'react-cookie';

import Homepage from '../components/Homepage/Homepage'
import BlogHomepage from '../components/Blog/BlogHomepage'
import BlogPost from '../components/Blog/BlogPost'
import Bucketlist from '../components/Bucketlist/Bucketlist'
import CountyCricket from '../components/CountyCricket/CountyCricket'
import Film from '../components/Film/Film'
import Finance from '../components/Finance/Finance'
import FinanceReview from '../components/Finance/FinanceReview'
import Scratchmap from '../components/Scratchmap/Scratchmap'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Login from '../components/Login/Login'
import NotFound from '../components/NotFound/NotFound'
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute'

import theme from '../components/Theme'

function AppRouter() {
    return (
        <CookiesProvider>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Navbar />
                        <Switch>
                            <Route path='/' component={ Homepage } exact />
                            <Route exact path='/countycricket' component={ CountyCricket }></Route>
                            <Route exact path='/blog' component={ BlogHomepage }></Route>
                            <Route path='/blog/:slug' component={ BlogPost }></Route>
                            <Route exact path='/film' component={ Film }></Route>
                            <ProtectedRoute exact path='/finance' component={ Finance }></ProtectedRoute>
                            <ProtectedRoute exact path='/review' component={ FinanceReview }></ProtectedRoute>
                            <Route exact path='/bucketlist' component={ Bucketlist }></Route>
                            <Route exact path='/scratchmap' component={ Scratchmap }></Route>
                            <Route exact path='/enter' component={ Login }></Route>
                            <Route component={ NotFound } />
                        </Switch>
                    <Footer />
                </BrowserRouter>
            </ThemeProvider>
        </CookiesProvider>
    )
}

export default AppRouter
