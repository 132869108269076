const getVisibleFilms = (films, searchParam) => {

    if(!searchParam) {
        return films.sort((a, b) => a.id < b.id ? 1 : -1)
    } else {
        return films.filter((film) => {
            return film.name.toLowerCase().includes(searchParam.toLowerCase())
        }).sort((a, b) => a.id < b.id ? 1 : -1)
    }
}

export default getVisibleFilms