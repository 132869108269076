import React from "react"
import {
  Route,
  Redirect
} from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { isExpired, decodeToken } from "react-jwt"

const ProtectedRoute = ({ component: Component }) => {

    const [cookies, ] = useCookies('kp_token');

    const checkAccessToken = () => {
        if(cookies['kp_token']){
            try {
                const token = cookies['kp_token']
                const myDecodedToken = decodeToken(token)
                if(myDecodedToken === null){
                    return false
                }
                return !isExpired(token)
            } catch (e) {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <Route render={ props =>
                checkAccessToken() ? (
                  <Component {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/enter",
                      state: { from: props.location }
                    }}
                  />
                )
              } />
    )
}

export default ProtectedRoute
