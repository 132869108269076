import React, { useState, useEffect} from 'react'
import ReactGA from 'react-ga';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { VectorMap } from "react-jvectormap"

import './jquery-jvectormap.css'

import headerImg from './header.jpg'

import config from '../../config.json';

const useStyles = makeStyles(theme => ({
    imageBackground:{
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        objectFit : 'cover',
        paddingTop: '5em'
    },
    title: {
        backgroundColor: '#000000',
    },
    root: {
        width: 300,
        height: 350,
        [theme.breakpoints.down('md')]: {
            width: 300,
            height: 300,
        }
    },
    media: {
        height: 140,
    },
    cardDate: {
        fontSize: '1.5em'
    }
}))

ReactGA.initialize(config.googleTrackingID);

const Scratchmap = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [mapData, setMapData] = useState({})
    const [countryCount, setCountryCount] = useState(0)
    const [countryPosts, setCountryPosts] = useState([])

    useEffect(() => {
        async function fetchUrl(url) {
            let response = await fetch('api/scratchmap/visited')
            let json = await response.json()
            const countries = json.countries
            setMapData(json.mapData)
            setCountryCount(json.count)
            
            let posts = await countries.map(async (country) => {
                if(country.blog_id){
                    response = await fetch('api/blog/post?id=' + country.blog_id)
                    json = await response.json()
                    json.country = country.name
                    json.code = country.code.toLowerCase()
                    return json
                } else {
                    return ''
                }
            })
            
            Promise.all(posts).then(function(results) {
                results = results.filter( r => r !== '')
                setCountryPosts(results)
            })

        }

        fetchUrl()
        ReactGA.pageview(window.location.pathname + window.location.search);
        
    }, [])

    const matchesXS =  useMediaQuery(theme.breakpoints.down('xs'))
    const matchesMD =  useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Grid container>
            {/* ----- Header Block ----- */}
            <Grid item container className={classes.imageBackground}>
                <Grid container direction='column' justify='center' alignItems="center" style={{ paddingTop : matchesXS ? '3em' : '10em', paddingBottom : matchesXS ? '3em' : '10em' }}>
                    <Grid item className={classes.title}>
                        <Typography variant='h1' >Scratchmap</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- About Block ----- */}
            <Grid item container direction='column' style={{padding : '2em'}} alignItems='center' justify= 'center'>
                <Grid item style={{paddingTop: matchesMD ? '2em' : 0 }}>
                    <Typography variant='h4' align='center' paragraph gutterBottom>I want to travel to every country in the world!</Typography>
                </Grid>
                <Grid item>
                    <Typography  variant='body1' align='center' paragraph gutterBottom>According to <Link to='https://www.fifa.com/associations/'>FIFA</Link>, there are 211 different countries.</Typography>
                </Grid>
                <Grid item>
                    <Typography  variant='body1' align='center' paragraph gutterBottom>For every country that I visit, I will write a blog post describing my trip, and the country will appear green in the map below.</Typography>
                </Grid>
                <Grid item style={{padding : '3em'}}>
                    <Typography  variant='h4' align='center' paragraph gutterBottom>Currently, I have travelled to {countryCount} different countries.</Typography>
                </Grid>
            </Grid>
            {/* ----- Map Block ----- */}
            <Grid container direction='column' style={{paddingTop : '2em', paddingBottom: '5em'}}>
                <Grid item container alignItems='center' justify='center' style={{ paddingLeft: '2em', paddingRight: '2em'}}>
                    <VectorMap
                        map={'world_mill'}
                        backgroundColor="transparent"
                        zoomOnScroll={true}
                        containerStyle={{
                            width: "100%",
                            height: "30em"
                        }}
                        containerClassName="map"
                        regionStyle={{
                            initial: {
                                fill: "#e4e4e4",
                                    "fill-opacity": 0.9,
                                stroke: "none",
                                    "stroke-width": 0,
                                    "stroke-opacity": 0
                            },
                            selected: {
                                fill: '#e4e4e4'
                            }
                        }}
                        regionsSelectable={true}
                        series={{
                        regions: [
                            {
                            values: mapData, //this is your data
                            scale: ["#e4e4e4", "#146804"],
                            normalizeFunction: "polynomial",
                            min: 0,
                            max: 1
                            }
                        ]
                        }}
                    />
                </Grid>
            </Grid>
            {/* ----- Countries Visited Block ----- */}
            <Grid container direction='column' style={{backgroundColor: theme.palette.common.screamingGreen }}>
                <Grid item style={{padding: matchesMD ? '2em' : '5em'}}>
                    <Typography variant='h2' align='center'>Countries Visited</Typography>
                </Grid>
                
                <Grid item>
                    <Grid item container direction='column' style={{padding : '2em'}} alignItems='center' justify= 'center'>
                        <Table className={classes.table} size="small" stickyHeader>
                            <TableHead className={classes.head} >
                                <TableRow>
                                    <TableCell align='center' style={{ backgroundColor : '#D0CFCF' }}></TableCell>
                                    <TableCell align='center' style={{ backgroundColor : '#D0CFCF' }}>Country</TableCell>
                                    <TableCell align='center' style={{ backgroundColor : '#D0CFCF' }}>Title</TableCell>
                                    <TableCell align='center' style={{ backgroundColor : '#D0CFCF' }}>Year</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {countryPosts.sort((a, b) => b.id- a.id).map((post, id) => (
                                <TableRow key={post.id} onClick={() => window.open('/blog/' + post.url, "_blank")} style={{ textDecoration : 'none', cursor : 'pointer', backgroundColor : parseInt(id) % 2 ? '#D0CFCF' : '#F2F2F2' }} hover>
                                    {/* https://flagicons.lipis.dev/ - link with all the flags TODO changing filenames to match actual country*/}
                                    <TableCell align="center"><img src={`/api/public/flags/${post.code}.svg`} alt={`${post.country} flag icon`} style={{ height : '50px' }} /></TableCell>
                                    <TableCell align="center">{post.country}</TableCell>
                                    <TableCell align="center">{post.title}</TableCell>
                                    <TableCell align="center">{post.created_date}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Scratchmap
