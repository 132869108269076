import React, { useState, useEffect} from 'react'
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'
import Paper from '@material-ui/core/Paper';
import {
    Check,
    Close,
    HourglassEmpty
} from '@material-ui/icons';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import headerImg from '../../assets/bucketlist/header.jpg'

import config from '../../config.json';

const useStyles = makeStyles(theme => ({
    imageBackground:{
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        paddingTop: '5em'
    },
}))

ReactGA.initialize(config.googleTrackingID);

const Bucketlist = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [bucketListItems, setBucketListItems] = useState([])
    const [filterBucketListItems, setFilterBucketListItems] = useState([])
    const [searchParam, setSearchParam] = useState('')
    const [searchCategory, setSearchCategory] = useState(-1)
    const [categories, setCategories] = useState([])
    const [percentComplete, setPercentCompete] = useState(0.00)

    useEffect(() => {
        async function fetchUrl(url) {
            // Set Bucket List items
            let response = await fetch('api/bucketlist/items')
            let json = await response.json()

            setBucketListItems(json)
            setFilterBucketListItems(json)

            const categories = json.map( i => { return { id : i['id'], name : i['name'] }})
            setCategories(categories)

            response = await fetch('api/bucketlist/percentage')
            json = await response.json()
            setPercentCompete(json.percentage)
        }

        fetchUrl()
        ReactGA.pageview(window.location.pathname + window.location.search);
        
    }, [])

    const matchesXS =  useMediaQuery(theme.breakpoints.down('xs'))
    const matchesSM =  useMediaQuery(theme.breakpoints.down('sm'))
    const matchesMD =  useMediaQuery(theme.breakpoints.down('md'))

    const handleSearchParam = (e) => {
        const newSearchParam = e.target.value
        setSearchParam(newSearchParam)

        const newItems = filteredItems(newSearchParam, searchCategory)
        setFilterBucketListItems(newItems)
    }

    const handleSearchCategory = (e) => {
        const newCategoryID = parseInt(e.target.value)
        setSearchCategory(newCategoryID)

        const newItems = filteredItems(searchParam, newCategoryID)
        setFilterBucketListItems(newItems)
    }

    const clearFilters = () => {
        setSearchParam('')
        setSearchCategory(-1)
        setFilterBucketListItems(bucketListItems)
    }

    const filteredItems = (search, category) => {
        let newItems = JSON.parse(JSON.stringify(bucketListItems))

        if(category !== -1){ // category is not empty
            newItems = newItems.filter(item => item.id === category)
        }

        if(search !== ''){ // search is not empty
            newItems.map( category => {
                category.kp_bucket_lists = category.kp_bucket_lists.filter(item => item.activity.toLowerCase().includes(search))
                return category
            })

            newItems = newItems.filter( item => item.kp_bucket_lists.length > 0)
        }

        return newItems
    }

    const handleItemClick = (link) => {
        if(Number.isInteger(parseInt(link))){
            return window.open('/blog/' + link, "_blank")
        }
        return window.open(link, "_blank")
    }


    return (
        <Grid>
            {/* ----- Header Block ----- */}
            <Grid item container className={classes.imageBackground}>
                <Grid container direction='column' justify='center' alignItems="center" style={{ paddingTop : matchesXS ? '3em' : '5em', paddingBottom : matchesXS ? '3em' : '5em' }} >
                    <Grid item>
                        <Typography variant='h1'>Bucket List</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- About Block ----- */}
            <Grid item container style={{padding : '2em'}} alignItems='center' justifyContent='space-around'>
                <Grid item sm={8} lg={10}>
                    <Grid container direction='column' align='center' justifyContent='center'>
                        <Grid item>
                            <Typography variant='body1' align={ matchesMD ? 'center' : 'left'} paragraph gutterBottom>A bucket list is a list of things that I want to do before I die... (or "kick the bucket" - where the phrase came from)</Typography>
                        </Grid>
                        <Grid item>
                            <Typography  variant='body1' align={ matchesMD ? 'center' : 'left'} paragraph gutterBottom>This list of items will grow over time and once I have checked something off, I will write a blog post documenting the activity.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={4} lg={2}>
                    <Grid container direction='column' alignItems='center' justifyContent='center'>
                        <Grid item style={{ width: 200, height: 200 }}>
                            <CircularProgressbar value={percentComplete} maxValue={100} text={`${percentComplete}%`} />
                        </Grid>
                    </Grid>
                </Grid>                
            </Grid>
            {/* ----- Search Block -----  */} 
            <Grid item container direction={  matchesXS ? 'column' : 'row' } alignItems='center' justify='space-around' style={{ padding: '1em', backgroundColor : '#D3D3D3' }}>
                <Grid item style={{ padding: matchesXS ? '1em' : undefined, paddingLeft: '1em', paddingRight: '1em' }} xs={12} sm={7}>
                    <TextField
                        id="bucketSearchInput"
                        fullWidth
                        value={searchParam}
                        onChange={handleSearchParam}
                        placeholder='Search by title...'
                        type='search'
                    />
                </Grid>
                <Grid item style={{ paddingLeft: '1em', paddingRight: '1em' }} xs={12} sm={3}>
                    <Select
                        id="bucketSearchSelect"
                        onChange={handleSearchCategory}
                        value={searchCategory}
                        fullWidth
                        placeholder='Category...'

                    >
                        { categories.map( category => {
                            return (
                                <MenuItem value={category.id}>{category.name}</MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
                <Grid item container alignItems='center' justify='center' xs={12} sm={2}  style={{ padding: matchesXS ? '1em' : undefined }}>
                    <Button variant="contained" color='primary' onClick={clearFilters}>Clear</Button>
                </Grid>
            </Grid>
            {/* ----- Bucketlist List ----- */}
            <Grid item container direction='column' alignItems='center' justify='center'>
                {filterBucketListItems.map((bucketListItem) => (
                    <React.Fragment key={bucketListItem.id}>            
                        <Grid item container direction='column' style={{padding : '1em'}}>
                            <Grid item style={{paddingBottom : '1em'}}>
                                <Typography variant='h3' align={matchesSM ? 'center' : 'left'} style={{paddingLeft: '1em', fontSize: '2em'}}>{bucketListItem.name}</Typography>
                            </Grid>
                            <Grid item style={{ padding : '2em' }}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>#</TableCell>
                                                <TableCell align='center'>Activity</TableCell>
                                                <TableCell align='center'>Completed?</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bucketListItem.kp_bucket_lists.map((item) => (
                                                <TableRow key={item.id} onClick={item.link === null ? undefined : () => handleItemClick(item.link)} style={{ background : item.completed === 5 ? '#FFFF7F' : item.completed ? "#AAC98B" : "#DCDCDC", cursor : item.completed === 5 ? undefined : item.completed ? 'pointer' : undefined }}>
                                                    <TableCell align="center">{item.id}</TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {item.activity}
                                                    </TableCell>
                                                    <TableCell align="center">{item.completed === 5 ? <HourglassEmpty /> : item.completed ?  <Check /> : <Close />}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid> 
        </Grid>
    )
}

export default Bucketlist
