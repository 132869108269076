import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

import { useCookies } from "react-cookie";

const FinanceReview = () => {
    const [years, setYears] = useState([]);
    const [currentYearTransactions, setCurrentYearTransactions] = useState([])
    const [previousYearTransactions, setPreviousYearTransactions] = useState([])
    const [categories, setCategories] = useState([]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [sidebarData, setSidebarData] = useState({});

    const [cookies] = useCookies("kp_token");

    useEffect(() => {
        async function fetchUrl() {

            // Get years
            let response = await fetch("api/finance/years", {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + cookies["kp_token"],
                }),
            });
            let json = await response.json();
            setYears(json);

            // Get Categories
            response = await fetch(`api/finance/categories`, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + cookies["kp_token"],
                }),
            });
            json = await response.json();
            setCategories(json);
            
            // Get Transactions
            // Current Year
            response = await fetch(
                `api/finance/transactions?year=${new Date().getFullYear()}`,
                {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + cookies["kp_token"],
                }),
                }
            );
            const currentTransJSON = await response.json();
            setCurrentYearTransactions(currentTransJSON);

            // Previous Year
            response = await fetch(
                `api/finance/transactions?year=${new Date().getFullYear()-1}`,
                {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + cookies["kp_token"],
                }),
                }
            );
            const previousTransJSON = await response.json();
            setPreviousYearTransactions(previousTransJSON);
        }

        fetchUrl();
    }, [cookies]);

    const handleChangeYear = async (e) => {
        const currentYear = parseInt(e.target.value)
        const previousYear = currentYear - 1

        // Get Transactions
        // Current Year
        let response = await fetch(
            `api/finance/transactions?year=${currentYear}`,
            {
            method: "GET",
            headers: new Headers({
                Authorization: "Bearer " + cookies["kp_token"],
            }),
            }
        );
        const currentTransJSON = await response.json();
        setCurrentYearTransactions(currentTransJSON);

        // Previous Year
        response = await fetch(
            `api/finance/transactions?year=${previousYear}`,
            {
            method: "GET",
            headers: new Headers({
                Authorization: "Bearer " + cookies["kp_token"],
            }),
            }
        );
        const previousTransJSON = await response.json();
        setPreviousYearTransactions(previousTransJSON);

        setCurrentYear(currentYear);
        setSidebarData({});
    };

    const handleOnClickValue = async (categoryID, categoryName, monthString) => {
        
        const currentYearTrans = currentYearTransactions
                                    .filter((t) => t.category_id === categoryID)
                                    .filter((t) => parseInt(moment().month(monthString).format("M")) === (moment(t.transaction_date, 'YYYY-MM-DD').month() + 1))

        const currentTotal = currentYearTrans
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        
        const previousYearTrans = previousYearTransactions
                                    .filter((t) => t.category_id === categoryID)
                                    .filter((t) => parseInt(moment().month(monthString).format("M")) === (moment(t.transaction_date, 'YYYY-MM-DD').month() + 1))
        
        const previousTotal = previousYearTrans
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)

        setSidebarData({
            amounts : {
                currentTotal,
                previousTotal
            },
            month: monthString,
            name: categoryName,
            currentData: currentYearTrans,
            oldData: previousYearTrans,
        });
    };

    const handleOnClickMonthTotal = async (monthString) => {
        const currentYearTrans = currentYearTransactions
                                    .filter((t) => parseInt(moment().month(monthString).format("M"))-1 === (moment(t.transaction_date, 'YYYY-MM-DD').month()))

        const currentTotal = currentYearTrans
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        
        const previousYearTrans = previousYearTransactions
                                    .filter((t) => parseInt(moment().month(monthString).format("M"))-1 === (moment(t.transaction_date, 'YYYY-MM-DD').month()))
        
        const previousTotal = previousYearTrans
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)

        return setSidebarData({
            amounts : {
                currentTotal,
                previousTotal
            },
            month: monthString,
            currentData: currentYearTrans,
            oldData: previousYearTrans,
        });
    };

    const handleOnClickCategoryDelta = async (categoryName, categoryID) => {
        const latestMonthInt = currentYearTransactions.map(t => (moment(t.transaction_date, 'YYYY-MM-DD').month())).reduce((a, b) => Math.max(a, b), 0)

        const currentYearTrans = currentYearTransactions
                                    .filter((t) => t.category_id === categoryID)
                                    .filter((t) => (moment(t.transaction_date, 'YYYY-MM-DD').month()) <= latestMonthInt)

        const currentTotal = currentYearTrans
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        
        const previousYearTrans = previousYearTransactions
                                    .filter((t) => t.category_id === categoryID)
                                    .filter((t) => (moment(t.transaction_date, 'YYYY-MM-DD').month()) <= latestMonthInt)
        
        const previousTotal = previousYearTrans
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        
        return setSidebarData({
            amounts : {
                currentTotal,
                previousTotal
            },
            name: categoryName,
            currentData: currentYearTrans,
            oldData: previousYearTrans,
        });
    };

    const calculateCategorySum = (categoryName) => {
        if (currentYearTransactions === undefined || currentYearTransactions.length === 0) {
            return "-";
        }

        const categoryID = categories.filter( c => c.name === categoryName)[0].id

        const currentAmount = currentYearTransactions
                                .filter((t) => t.category_id === categoryID)
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)

        return currentAmount.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const calculateMonthSum = (monthString) => {
        const currentAmount = currentYearTransactions
                                .filter((t) => parseInt(moment().month(monthString).format("M")) === (moment(t.transaction_date, 'YYYY-MM-DD').month() + 1))
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)

        return currentAmount;
    };

    const calculateCategoryDelta = (categoryID) => {
        if (currentYearTransactions === undefined || currentYearTransactions.length === 0){
            return "-"
        }

        const latestMonthInt = currentYearTransactions.map(t => (moment(t.transaction_date, 'YYYY-MM-DD').month())).reduce((a, b) => Math.max(a, b), 0)

        const currentTotal = currentYearTransactions
                                .filter((t) => t.category_id === categoryID)
                                .filter((t) => (moment(t.transaction_date, 'YYYY-MM-DD').month()) <= latestMonthInt)
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        
        const previousTotal = previousYearTransactions
                                .filter((t) => t.category_id === categoryID)
                                .filter((t) => (moment(t.transaction_date, 'YYYY-MM-DD').month()) <= latestMonthInt)
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)

        return currentTotal - previousTotal
    }

    const getCellBackgroundColour = (currentAmount, oldAmount) => {
        if(currentAmount === 0){
            return 'yellow'
        }
        return currentAmount === oldAmount ? 'yellow' : currentAmount < oldAmount ? '#FF7F7F' : '#90EE90'
    };

    const calculateOverallTotal = () => {
        if(currentYearTransactions === undefined || currentYearTransactions.length === 0){
            return 0
        }
        const total = currentYearTransactions
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        return total
    }

    const calculateOverallDelta = () => {
        if(currentYearTransactions === undefined || currentYearTransactions.length === 0){
            return 0
        }

        const latestMonthInt = currentYearTransactions.map(t => (moment(t.transaction_date, 'YYYY-MM-DD').month())).reduce((a, b) => Math.max(a, b), 0)

        const currentTotal = currentYearTransactions
                                .filter((t) => latestMonthInt <= (moment(t.transaction_date, 'YYYY-MM-DD').month()))
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        
        const previousTotal = previousYearTransactions
                                .filter((t) => latestMonthInt >= (moment(t.transaction_date, 'YYYY-MM-DD').month()))
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)

        return currentTotal - previousTotal
    }

    const calculateMonthCategoryTotalAmounts = (categoryID, monthString) => {
        const currentAmount = currentYearTransactions
                                .filter((t) => t.category_id === categoryID)
                                .filter((t) => parseInt(moment().month(monthString).format("M")) === (moment(t.transaction_date, 'YYYY-MM-DD').month() + 1))
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)
        
        const previousAmount = previousYearTransactions
                                .filter((t) => t.category_id === categoryID)
                                .filter((t) => parseInt(moment().month(monthString).format("M")) === (moment(t.transaction_date, 'YYYY-MM-DD').month() + 1))
                                .map(t => parseFloat(t.amount))
                                .reduce((a, b) => a + b, 0)

        return {
            currentAmount,
            previousAmount
        }
    }

    return (
        <Grid container direction="column" alignItems="center" justify="center" style={{ paddingTop: "6em" }}>
            <Grid item>
                <Typography variant="h2" align="center" style={{ color: "#000000" }}>Year Review</Typography>
            </Grid>
            <Grid item container>
                <Grid item lg={9} style={{ backgroundColor : 'yellow'}}>
                    {/* Year Select */}
                    <Grid item container alignItems="center" justify="center" style={{ backgroundColor: "red", flexGrow: 1, padding: "1em" }}>
                        {/* Year Filter */}
                        <Grid item>
                            <Select id="yearFilter" value={currentYear} onChange={handleChangeYear}>
                                {years.length !== 0 &&
                                    years.map((year) => (
                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    {/* Finance Values */}
                    <Grid item container style={{ backgroundColor: "yellow", flexGrow: 1, overflow: 'auto', minHeight : '100vh', maxHeight : '100vh' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Categories</TableCell>
                                    {moment.monthsShort().map((month) => (
                                        <TableCell align="left">{month}</TableCell>
                                    ))}
                                    <TableCell align="center">Total</TableCell>
                                    <TableCell align="center">+/-</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categories.map((category) => {
                                return (
                                    <TableRow key={category.name}>
                                        <TableCell align="center" component="th" scope="row">{category.name}</TableCell>
                                        {moment.months().map((month) => {
                                            const amounts = calculateMonthCategoryTotalAmounts(category.id, month)
                                            return (
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={() =>
                                                        handleOnClickValue(category.id, category.name, month)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: getCellBackgroundColour(amounts.currentAmount, amounts.previousAmount)
                                                    }}
                                                >
                                                    {amounts.currentAmount === 0 ? '-' : amounts.currentAmount.toLocaleString("en-GB",
                                                    {
                                                        style: "currency",
                                                        currency: "GBP",
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell align="center">{calculateCategorySum(category.name)}</TableCell>
                                        <TableCell align="center" style={{ backgroundColor : calculateCategoryDelta(category.id, category.name) < 0 ? '#FF7F7F' : calculateCategoryDelta(category.id, category.name) > 0 ? '#90EE90' : 'yellow', cursor : 'pointer' }} onClick={() => handleOnClickCategoryDelta(category.name, category.id)} >
                                            {calculateCategoryDelta(category.id, category.name).toLocaleString("en-GB", {
                                                style: "currency",
                                                currency: "GBP",
                                                minimumFractionDigits: 2,
                                            })}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                                <TableRow>
                                {/* Monthly Totals Row */}
                                <TableCell align="center" style={{ borderTop: "solid 3px" }}>Total</TableCell>
                                {moment.months().map((month) => {
                                    const sum = calculateMonthSum(month);
                                    return (
                                        <TableCell component="th" scope="row" style={{ borderTop: "solid 3px", backgroundColor: sum < 0 ? "#FF7F7F" : sum > 0 ? "#90EE90" : "yellow" }} onClick={() => handleOnClickMonthTotal(month)}>
                                            {sum === 0 ? '-' : sum.toLocaleString("en-GB", {
                                                style: "currency",
                                                currency: "GBP",
                                                minimumFractionDigits: 2,
                                            })}
                                        </TableCell>
                                    );
                                })}
                                {/* Overall Total */}
                                <TableCell component="th" scope="row" align='center' style={{ borderTop: "solid 3px", backgroundColor: calculateOverallTotal() < 0 ? "#FF7F7F" : calculateOverallTotal() > 0 ? "#90EE90" : "yellow" }}>
                                    {calculateOverallTotal().toLocaleString("en-GB", {
                                        style: "currency",
                                        currency: "GBP",
                                        minimumFractionDigits: 2,
                                    })}
                                </TableCell>
                                {/* Overall Delta */}
                                <TableCell component="th" scope="row" align='center' style={{ borderTop: "solid 3px", backgroundColor: calculateOverallDelta() < 0 ? "#FF7F7F" : calculateOverallDelta() > 0 ? "#90EE90" : "yellow" }}>
                                    {calculateOverallDelta().toLocaleString("en-GB", {
                                        style: "currency",
                                        currency: "GBP",
                                        minimumFractionDigits: 2,
                                    })}
                                </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                {/* Sidebar */}
                <Grid item container alignItems="center" direction="column" lg={3} style={{ backgroundColor: "green", flexGrow: 1, overflow: 'auto', minHeight : '120vh', maxHeight : '120vh' }}>
                    {Object.keys(sidebarData).length === 0 ? (
                        ""
                    ) : (
                        <Grid container direction='column' justify='center' alignItems='center'>
                            <Grid item container align="center" justify="space-between" style={{ padding: "1em" }}>
                                <Grid item>
                                    <Typography variant="h3" align="center">
                                        {sidebarData.name === undefined ? `${sidebarData.month} ${currentYear}` : sidebarData.month === undefined ? `${sidebarData.name}` : `${sidebarData.name} (${sidebarData.month} ${currentYear})`} 
                                        </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => setSidebarData({})}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Grid item container direction='column'>
                                <Grid item container justify='space-around' style={{ backgroundColor : '#D3D3D3', padding: '0.5em' }}>
                                    <Grid item>
                                        <Typography variant='body1' style={{ color : '#FFFFFF'}}>{currentYear}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body1' style={{ color : '#000000'}}>
                                            {sidebarData.amounts.currentTotal.toLocaleString("en-GB",
                                                {
                                                    style: "currency",
                                                    currency: "GBP",
                                                    minimumFractionDigits: 2,
                                                }
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justify='space-around' style={{ backgroundColor : '#D3D3D3', padding : '0.5em' }}>
                                    <Grid item>
                                        <Typography variant='body1' style={{ color : '#FFFFFF'}}>{currentYear-1}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body1' style={{ color : '#000000'}}>
                                            {sidebarData.amounts.previousTotal.toLocaleString("en-GB",
                                                {
                                                    style: "currency",
                                                    currency: "GBP",
                                                    minimumFractionDigits: 2,
                                                }
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item container justify="flex-start" style={{ paddingTop : '1em', paddingLeft: "1em" }}>
                                <Grid item>
                                    <Typography variant="h3" align="left">{currentYear}</Typography>
                                </Grid>
                            </Grid>

                            {/* Current Data Table */}
                            <Grid item style={{ padding: "1em" }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">Description</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sidebarData.currentData.map((transaction) => (
                                            <TableRow
                                                key={transaction.transaction_id}
                                                style={{
                                                backgroundColor:
                                                    transaction.amount < 0 ? "#FF7F7F" : "#90EE90",
                                                }}
                                            >
                                                <TableCell align="center" component="th" scope="row">{transaction.transaction_date}</TableCell>
                                                <TableCell align="center" component="th" scope="row">{transaction.description}</TableCell>
                                                <TableCell align="center" component="th" scope="row">{transaction.amount}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        
                            {/* Old Data Table */}
                            <Grid item container justify="flex-start" style={{ paddingTop : '1em', paddingLeft: "1em" }}>
                                <Grid item>
                                    <Typography variant="h3" align="left">{currentYear - 1}</Typography>
                                </Grid>
                            </Grid>
                            
                            <Grid item style={{ padding: "1em" }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">Description</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sidebarData.oldData.map((transaction) => (
                                            <TableRow
                                                key={transaction.transaction_id}
                                                style={{
                                                backgroundColor:
                                                    transaction.amount < 0 ? "#FF7F7F" : "#90EE90",
                                                }}
                                            >
                                                <TableCell align="center" component="th" scope="row">{transaction.transaction_date}</TableCell>
                                                <TableCell align="center" component="th" scope="row">{transaction.description}</TableCell>
                                                <TableCell align="center" component="th" scope="row">{transaction.amount}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FinanceReview;
