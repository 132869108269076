import React, { useState, useEffect} from 'react'
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Pagination from '@material-ui/lab/Pagination'

import { useLocation } from "react-router-dom";

import headerImg from '../../assets/blog/header.jpg'
import { TextField } from '@material-ui/core'
import config from '../../config.json';

const useStyles = makeStyles(theme => ({
    imageBackground:{
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
        paddingTop: '5em'
    },
    title: {
        textAlign : 'center'
    },
    root: {
        width: 300,
        height: 350,
        [theme.breakpoints.down('md')]: {
            width: 300,
            height: 300,
        }
    },
    media: {
        height: 200,
    },
    cardDate: {
        fontSize: '1.5em'
    }
}))

ReactGA.initialize(config.googleTrackingID);

const BlogHomepage = () => {
    let search = useLocation().search
    const category = new URLSearchParams(search).get('category');

    const classes = useStyles()
    const theme = useTheme()

    const [blogPosts, setBlogPosts] = useState([])
    const [filterBlogPosts, setFilterBlogPosts] = useState([])
    const [categories, setCategories] = useState([])
    const [searchParam, setSearchParam] = useState('')
    const [searchCategory, setSearchCategory] = useState(-1)
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(0)
    const [onHoverStyle, setOnHoverStyle] = useState([])
    const pageSize = 12

    const matchesXS =  useMediaQuery(theme.breakpoints.down('xs'))
    const matchesSM =  useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        async function fetchUrl() {
            // Set Blog Posts
            let response = await fetch('/api/blog/posts')
            const blogJSON = await response.json()
            setBlogPosts(blogJSON)
            setFilterBlogPosts(blogJSON.slice(0, pageSize))
            setPageLimit(Math.ceil(blogJSON.length / pageSize))
            setOnHoverStyle(new Array(blogJSON.slice(0, pageSize).length).fill(false))

            // Set Blog Categories
            response = await fetch('/api/blog/categories')
            const json = await response.json()
            setCategories(json)
            
            if(category !== undefined && category !== null){
                const sc = json.filter(j => j.name.toLowerCase() === category.toLowerCase())[0]
                // if category exists then do the rest
                if(sc !== undefined){
                    const filteredBlogJSON = blogJSON.filter(post => post.category_id === sc.id)
                    setSearchCategory(sc.id)
                    setFilterBlogPosts(filteredBlogJSON.slice(0, pageSize))
                    setPageLimit(Math.ceil(filteredBlogJSON.length / pageSize))
                    setOnHoverStyle(new Array(filteredBlogJSON.slice(0, pageSize).length).fill(false))
                }
            }
        }

        ReactGA.pageview(window.location.pathname + window.location.search)
        fetchUrl()
        
    }, [category])

    const handleChangePage = (event, value) => {
        const startIndex = (value-1) * pageSize
        const newBlogPosts = filteredBlogPosts(searchParam, searchCategory)
        
        setFilterBlogPosts(newBlogPosts.slice(startIndex, startIndex + pageSize))
        setOnHoverStyle(new Array(newBlogPosts.slice(0, pageSize).length).fill(false))
        setPage(value)
    };
    
    const handleSearchParam = (e) => {
        const newSearchParam = e.target.value
        setSearchParam(newSearchParam)

        const newBlogPosts = filteredBlogPosts(newSearchParam, searchCategory)
        setFilterBlogPosts(newBlogPosts.slice(0, pageSize))
        setPage(1)
        setPageLimit(Math.ceil(newBlogPosts.length / pageSize))
        setOnHoverStyle(new Array(newBlogPosts.slice(0, pageSize).length).fill(false))
    }

    const handleSearchCategory = (e) => {
        const newCategoryID = parseInt(e.target.value)
        setSearchCategory(newCategoryID)

        const newBlogPosts = filteredBlogPosts(searchParam, newCategoryID)
        setFilterBlogPosts(newBlogPosts.slice(0, pageSize))
        setPage(1)
        setPageLimit(Math.ceil(newBlogPosts.length / pageSize))
        setOnHoverStyle(new Array(newBlogPosts.slice(0, pageSize).length).fill(false))
    }

    const filteredBlogPosts = (search, category) => {
        let newBlogPosts = JSON.parse(JSON.stringify(blogPosts))

        if(search !== ''){ // search is not empty
            newBlogPosts = newBlogPosts.filter(blog => blog.title.toLowerCase().includes(search))
        }

        if(category !== -1){ // category is not empty
            newBlogPosts = newBlogPosts.filter(blog => blog.category_id === category)
        }

        return newBlogPosts
    }

    const handleOnHover = (index) => {
        const newOnHoverStyle = JSON.parse(JSON.stringify(onHoverStyle)).map((val, i) => {
            if(index === i){
                return !val
            }
            return val
        })
        setOnHoverStyle(newOnHoverStyle)
    }

    const clearFilters = () => {
        setFilterBlogPosts(blogPosts.slice(0, pageSize))
        setPageLimit(Math.ceil(blogPosts.length / pageSize))
        setPage(0)
        setSearchParam('')
        setSearchCategory(-1)
    }

    return (
        <Grid>
            {/* ----- Header Block ----- */}
            <Grid item container className={classes.imageBackground}>
                <Grid container direction='column' justify='center' alignItems="center" style={{ paddingTop : matchesXS ? '3em' : '5em', paddingBottom : matchesXS ? '3em' : '5em' }}>
                    <Grid item>
                        <Typography variant='h1' className={classes.title}>Blog</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- Blog Search Block ----- */}
            <Grid item container alignItems='center' justify='space-around' style={{ padding: '1em', backgroundColor : '#D3D3D3' }}>
                <Grid item style={{ padding: matchesXS ? '1em' : undefined, paddingLeft: '1em', paddingRight: '1em' }} xs={12} sm={7}>
                    <TextField
                        id="blogSearchInput"
                        fullWidth
                        value={searchParam}
                        onChange={handleSearchParam}
                        placeholder='Search by title...'
                        type='search'
                    />
                </Grid>
                <Grid item style={{ padding: matchesXS ? '1em' : undefined, paddingLeft: '1em', paddingRight: '1em' }} xs={12} sm={3}>
                    <Select
                        id="blogSearchSelect"
                        onChange={handleSearchCategory}
                        value={searchCategory}
                        fullWidth
                        placeholder='Category...'

                    >
                        { categories.map( category => {
                            return (
                                <MenuItem value={category.id}>{category.name}</MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
                <Grid item container alignItems='center' justify='center' xs={12} sm={2}  style={{ padding: matchesXS ? '1em' : undefined }}>
                    <Button variant="contained" color='primary' onClick={clearFilters}>Clear</Button>
                </Grid>
            </Grid>
            
            <Grid item container direction='column' alignItems='center'>

            {/* ----- Blog Posts Block ----- */}
            <Grid item container direction='row' justify='space-around' style={{ paddingTop : '1em', paddingLeft : '1em', paddingRight : '1em', width : '90%' }}>
                {filterBlogPosts.sort((a, b) => b.id- a.id).map((post, index) => (
                    <Grid item style={{ padding : '1em'}}>
                        <Grid item container alignItems='center' justify='center' onClick={() => window.open('/blog/' + post.url, '_self')} style={{ backgroundImage : onHoverStyle[index] ? undefined : `url("/api/public/blog/${post.id}_${post.url}/img/header.jpg")`, backgroundColor : onHoverStyle[index] ? '#000000' : undefined, height : matchesSM ? '200px' : '300px', width : matchesSM ? '200px' : '300px', backgroundSize: 'cover', cursor : 'pointer', opacity: onHoverStyle[index] ? '0.7' : undefined }} onMouseEnter={() => handleOnHover(index)} onMouseLeave={() => handleOnHover(index)}>
                        <Grid item style={{ display : onHoverStyle[index] ? 'block': 'none', backgroundColor : '#000000', opacity : '0.7', width : '100%'}}>
                            <Typography align='center' style={{ color : 'white' }}>#{post.id} {post.title}</Typography>
                        </Grid>    
                        </Grid>
                    </Grid>
                ))}
                
            </Grid>

            {/* ----- Blog Posts Pagination ----- */}
            <Grid item style={{ paddingBottom : '1em', paddingLeft : '1em', paddingRight : '1em' }}>
                <Pagination count={pageLimit} page={page} color="primary" onChange={handleChangePage}/>
            </Grid>

            </Grid>

        </Grid>

    )
}

export default BlogHomepage
