import 'date-fns';
import React, { useEffect, useState } from 'react'
import Avatar from "@material-ui/core/Avatar";
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FoodIcon from '@material-ui/icons/Fastfood';
import TransferIcon from '@material-ui/icons/FastForward';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PetrolIcon from '@material-ui/icons/LocalGasStation';
import SaveIcon from '@material-ui/icons/Save';
import UtilitiesIcon from '@material-ui/icons/Build';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment'
import { useCookies } from 'react-cookie'

const useStyles = makeStyles(theme => ({
    section: {
        padding: "1rem",
        borderStyle: "solid",
        borderRadius: "25px"
    },
    input: {
        fontSize : '10pt'
    }
}))

const Finance = () => {
    const classes = useStyles()
    const [totals, setTotals] = useState([])
    const [cookies, ] = useCookies('kp_token')
    const [account, setAccount] = useState(-1)
    const [dateGroups, setDateGroups] = useState([])
    const [month, setMonth] = useState('')
    const [transactions, setTransactions] = useState([])
    const [newTransactions, setNewTransactions] = useState([])
    const [defaults, setDefaults] = useState({})
    const [accounts, setAccounts] = useState([])
    const [categories, setCategories] = useState([])
    const [descriptions, setDescriptions] = useState([])
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editTransaction, setEditTransaction] = useState({})
    const [filteredDescriptions, setFilteredDescriptions] = useState([])
    const [isDescSearch, setIsDescSearch] = useState(false)

    useEffect(() => {
        async function fetchUrl() {
            // Get Totals
            let response = await fetch('api/finance/totals', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            let json = await response.json()
            setTotals(json)

            response = await fetch('api/finance/dates', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            json = await response.json()
            setDateGroups(json)

            response = await fetch('api/finance/defaults', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            json = await response.json()
            Object.keys(json).map( key => {
                const stuff = json[key].map(nt => {
                    nt.date = moment().format('yyyy-MM-DD')
                    return nt
                })
                json[key] = stuff
                return ''
            })
            setDefaults(json)

            response = await fetch('api/finance/categories', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            json = await response.json()
            setCategories(json)

            response = await fetch('api/finance/accounts', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            json = await response.json()
            setAccounts(json)

            response = await fetch('api/finance/descriptions', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            json = await response.json()
            setDescriptions(json)
            setFilteredDescriptions(json)
        }

        fetchUrl()
        
    }, [cookies])

    const selectBagroundColour = (accountName) => {
        switch(accountName){
            case 'AMEX':
                return '#006FCF'
            case 'Monzo':
                return '#13233C'   
            case 'Monzo Saving':
                return '#13233C'
            case 'HSBC':
                return '#D3D3D3'    
            default:
                return '#FFFFFF'
        }
    }

    const getTransactions = async (month, year, account) => {
        const response = await fetch(`api/finance/transactions?account=${account}&month=${month}&year=${year}`, {
            method : 'GET',
            headers : new Headers({
                'Authorization' : 'Bearer '+cookies['kp_token']
            })
        })
        const json = await response.json()
        setTransactions(json)
    }

    const handleClickAccount = async (accountID) => {
        setIsDescSearch(false)
        setAccount(accountID)
        if(month !== ''){
            const date = moment(month, 'MMMM YYYY')
            const monthNum = date.format('M');
            const year  = date.format('YYYY');
            await getTransactions(monthNum, year, accountID)
        }
    }

    const handleChangeMonth = async (e) => {
        setMonth(e.target.value)

        const date = moment(e.target.value, 'MMMM YYYY')
        const monthNum = date.format('M');
        const year  = date.format('YYYY');

        await getTransactions(monthNum, year, account)
    }

    const handleAddNewTransaction = (account=undefined) => {
        const newTrans = {
            type : '+',
            date : moment().format('yyyy-MM-DD')
        }
        if(account !== undefined){
            newTrans['account'] = account
        }
        setNewTransactions([
            ...newTransactions,
            newTrans
        ])
    }

    const handleAddNewTransfer = () => {
        setNewTransactions([
            ...newTransactions,
            {
                type : '>',
                date : moment().format('yyyy-MM-DD')
            }
        ])
    }

    const handleEditNewTransaction = (transactionIndex, key, value) => {
        const newTrans = JSON.parse(JSON.stringify(newTransactions))

        if(key === 'description' && value !== ''){
            setIsDescSearch(true)
            const filteredDescs = descriptions.filter(d => d.description.toLowerCase().includes(value.toLowerCase()))
            setFilteredDescriptions(filteredDescs)
        } else {
            setFilteredDescriptions(descriptions)
        }

        if(value === ''){
            delete newTrans[transactionIndex][key]
        } else {
            newTrans[transactionIndex][key] = key === 'amount' ? !isNaN(parseFloat(value)) ? value : newTrans[transactionIndex][key] : value
        }
        delete newTrans[transactionIndex].error
        setNewTransactions(newTrans)
        
    }

    const handleAddDefaultTransaction = (key) => {

        const newTrans = JSON.parse(JSON.stringify(defaults[key]))

        setNewTransactions([
            ...newTransactions,
            ...newTrans
        ])
    }

    const handleDeleteNewTransaction = (index) => {
        const newTrans = JSON.parse(JSON.stringify(newTransactions))
        newTrans.splice(index, 1)
        setNewTransactions(newTrans)
    }

    const handleDeleteTransaction = async (transactionID) => {
        const response = await fetch(`api/finance/transaction`, {
            method : 'DELETE',
            body: JSON.stringify({ transaction_id : transactionID}),
            headers : new Headers({
                'Authorization' : 'Bearer '+cookies['kp_token'],
                'Content-Type': 'application/json'
            })
        })

        if(response.status === 204){
            // retrieve totals
            let response = await fetch('api/finance/totals', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            let json = await response.json()
            setTotals(json)

            // retrieve transactions
            let newTrans = JSON.parse(JSON.stringify(transactions)).filter( t => t.id !== transactionID)
            setTransactions(newTrans)
            
        }
    }

    const handleSaveTransactions = async () => {
        if(newTransactions.length === 0){
            return 
        }
        // Check all transactions are valid before submitting
        let allValid = true
        const newTrans = JSON.parse(JSON.stringify(newTransactions))

        newTrans.map((transaction, index) => {
            if(transaction.type === '+'){
                if(!(Object.keys(transaction).includes('date') && Object.keys(transaction).includes('account') && !isNaN(transaction.amount) && Object.keys(transaction).includes('amount') && Object.keys(transaction).includes('category') && Object.keys(transaction).includes('description'))){
                    newTrans[index].error = true
                    allValid = false
                    return ''
                }

            } else if(transaction.type === '>'){
                if(!(Object.keys(transaction).includes('date') && Object.keys(transaction).includes('account') && !isNaN(transaction.amount) && Object.keys(transaction).includes('to') && Object.keys(transaction).includes('amount') && Object.keys(transaction).includes('category') && Object.keys(transaction).includes('description'))){
                    newTrans[index].error = true
                    allValid = false
                    return ''
                }
            }
            return ''
        })

        if(allValid){
            const response = await fetch(`api/finance/transactions`, {
                method : 'POST',
                body: JSON.stringify(newTrans),
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token'],
                    'Content-Type': 'application/json'
                })
            })
            const json = await response.json()
            setTotals(json)

            setNewTransactions([])
        } else {
            setNewTransactions(newTrans)
        }
    }

    const handleClearNewTransactions = () => {
        setNewTransactions([])
    }

    const handleOpenEditTransaction = (transaction) => {
        transaction.category = categories.filter( c => c.id === transaction.category_id)[0].name
        transaction.account = accounts.filter( c => c.id === transaction.account_id)[0].name
        delete transaction.category_id
        delete transaction.account_id

        setEditTransaction(transaction)
        setOpenEditDialog(true)
    }

    const handleEditTransaction = (key, value) => {
        const transaction = JSON.parse(JSON.stringify(editTransaction))
        transaction[key] = value
        setEditTransaction(transaction)
    }

    const handleSaveEditTransaction = async () => {
        const transaction = JSON.parse(JSON.stringify(editTransaction))

        transaction.category_id = categories.filter( c => c.name === transaction.category)[0].id
        transaction.account_id = accounts.filter( a => a.name === transaction.account)[0].id
        delete transaction.category
        delete transaction.account

        const response = await fetch(`api/finance/transaction`, {
            method : 'PATCH',
            body: JSON.stringify(transaction),
            headers : new Headers({
                'Authorization' : 'Bearer '+cookies['kp_token'],
                'Content-Type': 'application/json'
            })
        })

        if(response.status === 204){
            // retrieve totals
            let response = await fetch('api/finance/totals', {
                method : 'GET',
                headers : new Headers({
                    'Authorization' : 'Bearer '+cookies['kp_token']
                })
            })
            let json = await response.json()
            setTotals(json)

            const newTransactions = []

            transactions.map(t => {
                if(t.id === transaction.id){
                    t.account_id = accounts.filter( a => a.name === t.account)[0].id
                    if(t.account_id === transaction.account_id){
                        return newTransactions.push(transaction)
                    }
                } else {
                    return newTransactions.push(t)
                }
                return ''
            })

            setTransactions(newTransactions)
        }

        setOpenEditDialog(false)
    }

    const calcMonzoAggregate = (totals) => {
        if(totals.length === 0){
            return 0
        } else {
            return totals.filter(account => account.name.includes('Monzo')).map(account => parseFloat(account.total)).reduce((a, b) => a + b, 0)
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction="column" alignItems="center" justify="center" style={{ paddingTop: "6em" }}>
                <Grid item>
                    <Typography variant="h2" align="center" style={{ color: "#000000" }}>Finance</Typography>
                </Grid>
                <Grid item container alignItems="center" justify="space-around" style={{ backgroundColor: "red" }}>
                    {totals !== undefined && totals.map( account => {
                        return (
                            <Grid item xs={12} lg={4} style={{ padding : '1em'}} onClick={() => handleClickAccount(account.account_id)} >
                                <Grid item container alignItems='center' justify='space-around' className={ classes.section } style={{ backgroundColor : selectBagroundColour(account.name) }}>
                                    <Grid item xs={7}>
                                        <img src={`/api/public/finance/${account.name.toLowerCase().replace(' ', '_')}.png`} style={{ height : '100px' }} alt={`${account} Logo`}/>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant='h2' style={{ color : account.total >= 0 ? 'green' : 'red' }} >£{account.total}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                    { /* Aggregate Monzo */}
                    {
                        totals.length !== 0 && (
                            <Grid item xs={12} lg={4} style={{ padding : '1em'}}>
                                <Grid item container alignItems='center' justify='space-around' className={ classes.section } style={{ backgroundColor : selectBagroundColour('Monzo') }}>
                                    <Grid item xs={7}>
                                        <img src={`/api/public/finance/monzo_agg.png`} style={{ height : '100px' }} alt={`Monzo Logo`}/>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant='h2' style={{ color : calcMonzoAggregate(totals)  >= 0 ? 'green' : 'red' }} >£{calcMonzoAggregate(totals)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
                <Grid item container>
                    { isDescSearch ?
                        
                        <Grid item lg={4} style={{ backgroundColor: "gray", flexGrow: 1, overflow: 'auto', minHeight : '100vh', maxHeight : '100vh'}} >                                
                            <Grid item container alignItems='center' justify='center'>
                                <Grid item style={{ paddingTop : '1em'}}>
                                    <Typography variant='h2' style={{ color : '#FFFFFF'}}>Previous Descriptions</Typography>
                                </Grid>
                                
                                <Grid item container style={{ padding : '5em', paddingTop: '1em'}}>
                                    <Table size="small" style={{ backgroundColor : '#DCDCDC'}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Description</TableCell>
                                                <TableCell align="center">Category</TableCell>
                                                <TableCell align="center">Count</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredDescriptions.length === 0 ? 
                                                <TableRow style={{ backgroundColor: "#D3D3D3" }}>
                                                    <TableCell colSpan={3} align="center" component="th" scope="row">No Data!</TableCell>
                                                </TableRow>
                                                : 
                                                filteredDescriptions.map((d) => (
                                                <TableRow
                                                >
                                                    <TableCell align="center" component="th" scope="row">{d.description}</TableCell>
                                                    <TableCell align="center" component="th" scope="row">{d.category}</TableCell>
                                                    <TableCell align="center" component="th" scope="row">{d.count}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>

                        : 
                        
                        <Grid item lg={4} style={{ backgroundColor: "green", flexGrow: 1, overflow: 'auto', minHeight : '100vh', maxHeight : '100vh'}} >
                            { account > -1 && 
                                
                                <Grid item container alignItems='center' justify='center'>
                                    <Grid item style={{ paddingTop : '1em'}}>
                                        <Typography variant='h2' style={{ color : '#FFFFFF'}}>{totals.filter( t => t.account_id === account)[0].name} Transactions</Typography>
                                    </Grid>

                                    <Grid item container alignItems='center' justify='center'>
                                        <Grid item style={{ paddingTop : '1em'}}>
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel>Month</InputLabel>
                                                <Select
                                                    native
                                                    value={month}
                                                    onChange={handleChangeMonth}
                                                    inputProps={{
                                                        name: 'month',
                                                    }}
                                                    fullWidth
                                                    style={{ minWidth : '300px', backgroundColor : '#D3D3D3'}}
                                                >
                                                    <option value=""/>
                                                    {dateGroups.map(date => {
                                                        return (
                                                            <option value={date}>{date}</option>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    { month !== '' && 
                                    <Grid item container style={{ padding : '1em'}}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" style={{ color : '#FFFFFF' }}>ID</TableCell>
                                                    <TableCell align="center" style={{ color : '#FFFFFF' }}>Date</TableCell>
                                                    <TableCell align="center" style={{ color : '#FFFFFF' }}>Description</TableCell>
                                                    <TableCell align="center" style={{ color : '#FFFFFF' }}>Amount</TableCell>
                                                    <TableCell align="center" style={{ color : '#FFFFFF' }}>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {transactions.length === 0 ? 
                                                    <TableRow style={{ backgroundColor: "#D3D3D3" }}>
                                                        <TableCell align="center" component="th" scope="row"></TableCell>
                                                        <TableCell align="center" component="th" scope="row"></TableCell>
                                                        <TableCell align="center" component="th" scope="row">No Data!</TableCell>
                                                        <TableCell align="center" component="th" scope="row"></TableCell>
                                                        <TableCell align="center" component="th" scope="row"></TableCell>
                                                    </TableRow>
                                                    : 
                                                    transactions.map((transaction) => (
                                                    <TableRow
                                                        key={transaction.transaction_id}
                                                        style={{
                                                        backgroundColor:
                                                            transaction.amount < 0 ? "#FF7F7F" : "#90EE90",
                                                        }}
                                                    >
                                                        <TableCell align="center" component="th" scope="row">{transaction.id}</TableCell>
                                                        <TableCell align="center" component="th" scope="row">{transaction.transaction_date}</TableCell>
                                                        <TableCell align="center" component="th" scope="row">{transaction.description}</TableCell>
                                                        <TableCell align="center" component="th" scope="row">{transaction.amount}</TableCell>
                                                        <TableCell align="center" component="th" scope="row">
                                                            <IconButton color="primary" size='small' onClick={() => {handleOpenEditTransaction(transaction)}}>
                                                                <EditIcon fontSize="small"/>
                                                            </IconButton>
                                                            <IconButton color="primary" size='small' onClick={() => handleDeleteTransaction(transaction.id)} style={{ paddingLeft : '0.25em'}}>
                                                                <DeleteIcon fontSize="small"/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                    
                    }
                    <Grid item lg={8} style={{ backgroundColor: "yellow", flexGrow: 1, overflow: 'auto', minHeight : '100vh', maxHeight : '100vh'}}>
                        <Grid container>
                            <Grid item lg={11} style={{ backgroundColor : '#006FCF', flexGrow: 1, overflow: 'auto', minHeight : '100vh', maxHeight : '100vh', padding : '1em' }}>
                            {newTransactions.length !== 0 && 
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Type</TableCell>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Date</TableCell>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Account (From)</TableCell>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Account (To)</TableCell>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Amount</TableCell>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Category</TableCell>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Description</TableCell>
                                            <TableCell align="center" style={{ color : '#FFFFFF' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {newTransactions.map((transaction, index) => (
                                            <TableRow
                                                style={{
                                                backgroundColor:
                                                    transaction.error ? 'red' : (transaction.amount === undefined || transaction.amount === 0) ? '#FFFFFF' : transaction.amount < 0 ? "#FF7F7F" : "#90EE90",
                                                }}
                                            >
                                                <TableCell align="center" component="th" scope="row">
                                                    {transaction.type}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        format="yyyy-MM-dd"
                                                        margin="normal"
                                                        id="date"
                                                        value={transaction.date}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        onChange={(e) => {handleEditNewTransaction(index, 'date', moment(e, "yyyy-MM-DD"))}}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    <Select
                                                        style={{ fontSize : '10pt'}}
                                                        native
                                                        value={transaction.account}
                                                        onChange={(e) => handleEditNewTransaction(index, 'account', e.target.value)}
                                                        fullWidth
                                                    >
                                                        <option value=""/>
                                                        {accounts.map(acc => {
                                                            return (
                                                                <option value={acc.name}>{acc.name}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    { transaction.type === '+' ? '-' : 
                                                        <Select
                                                            style={{ fontSize : '10pt'}}
                                                            native
                                                            value={transaction.to}
                                                            onChange={(e) => handleEditNewTransaction(index, 'to', e.target.value)}
                                                            fullWidth
                                                        >
                                                            <option value=""/>
                                                            {accounts.map(acc => {
                                                                return (
                                                                    <option value={acc.name}>{acc.name}</option>
                                                                )
                                                            })}
                                                        </Select>
                                                    }
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    <TextField
                                                        inputProps={{style: {fontSize: '10pt'}}}
                                                        placeholder='Amount'
                                                        value={transaction.amount}
                                                        onChange={(e) => handleEditNewTransaction(index, 'amount', e.target.value)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    <Select
                                                        style={{ fontSize : '10pt'}}
                                                        native
                                                        value={transaction.category}
                                                        onChange={(e) => handleEditNewTransaction(index, 'category', e.target.value)}
                                                        fullWidth
                                                    >
                                                        <option value=""/>
                                                        {categories.map(cat => {
                                                            return (
                                                                <option value={cat.name}>{cat.name}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    <TextField
                                                        inputProps={{style: {fontSize: '10pt'}}}
                                                        placeholder='Description'
                                                        value={transaction.description}
                                                        onChange={(e) => handleEditNewTransaction(index, 'description', e.target.value)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    <IconButton color="primary" onClick={() => handleDeleteNewTransaction(index)}>
                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            }
                            </Grid>
                            <Grid item lg={1} style={{ backgroundColor : 'orange', flexGrow: 1, overflow: 'auto', minHeight : '100vh', maxHeight : '100vh' }}>
                                <Grid container direction='column' align='center' justify='center'>
                                    <Grid item>
                                        <IconButton color="primary" onClick={handleSaveTransactions}>
                                            <SaveIcon fontSize="large"/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={() => handleAddNewTransaction('AMEX')}>
                                            <Avatar  alt="AMEX Logo" src={`/api/public/finance/amex.png`}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={() => handleAddNewTransaction('Monzo')}>
                                            <Avatar  alt="Monzo Logo" src={`/api/public/finance/monzo.png`}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={() => handleAddNewTransaction('HSBC')}>
                                            <Avatar  alt="HSBC Logo" src={`/api/public/finance/hsbc.png`}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={handleAddNewTransaction}>
                                            <AddIcon fontSize="large"/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={handleAddNewTransfer}>
                                            <TransferIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary"  onClick={() => handleAddDefaultTransaction('food')}>
                                            <FoodIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary"  onClick={() => handleAddDefaultTransaction('petrol')}>
                                            <PetrolIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={() => handleAddDefaultTransaction('home')}>
                                            <HomeIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary"  onClick={() => handleAddDefaultTransaction('utilities')}>
                                            <UtilitiesIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={handleClearNewTransactions}>
                                            <DeleteIcon fontSize="large"/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Grid>
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle>Edit Transaction</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date"
                                fullWidth
                                value={editTransaction.transaction_date}
                                InputProps={{
                                    className: classes.input,
                                }}
                                onChange={(e) => handleEditTransaction('date', moment(e, "yyyy-MM-DD"))}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item style={{ paddingTop : '1em' }}>
                            <Select
                                style={{ fontSize : '10pt', width: '100%'}}
                                native
                                value={editTransaction.account}
                                onChange={(e) => handleEditTransaction('account', e.target.value)}
                                fullWidth
                            >
                                <option value=""/>
                                {accounts.map(acc => {
                                    return (
                                        <option value={acc.name}>{acc.name}</option>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid item style={{ paddingTop : '1em' }}>
                            <Select
                                style={{ fontSize : '10pt'}}
                                native
                                value={editTransaction.category}
                                onChange={(e) => handleEditTransaction('category', e.target.value)}
                                fullWidth
                            >
                                <option value=""/>
                                {categories.map(cat => {
                                    return (
                                        <option value={cat.name}>{cat.name}</option>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid item style={{ paddingTop : '1em' }}>
                            <TextField
                                fullWidth
                                inputProps={{style: {fontSize: '10pt'}}}
                                placeholder='Amount'
                                value={editTransaction.amount}
                                onChange={(e) => handleEditTransaction('amount', e.target.value)}
                            />
                        </Grid>
                        <Grid item style={{ paddingTop : '1em' }}>
                            <TextField
                                fullWidth
                                inputProps={{style: {fontSize: '10pt'}}}
                                placeholder='Description'
                                value={editTransaction.description}
                                onChange={(e) => handleEditTransaction('description', e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSaveEditTransaction} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider>
    )
}

export default Finance
