import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import getVisibleFilms from '../../selectors/getVisibleFilms'

import headerImg from '../../assets/film/header.jpg'

import config from '../../config.json';

const useStyles = makeStyles(theme => ({
    imageBackground:{
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        objectFit : 'cover',
        paddingTop: '5em'
    },
    head: {
        backgroundColor: theme.palette.common.green,
        color: '#FFFFFF',
    },
    title: {
    },
    table: {
        [theme.breakpoints.down('xl')]: {
            maxWidth: '75%',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '60%',
        }
        
    }
}))

ReactGA.initialize(config.googleTrackingID);

const Film = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [filmCount, setFilmCount] = useState(0)
    const [films, setFilms] = useState([])
    const [searchParam, setSearchParam] = useState('')

    const matchesXS =  useMediaQuery(theme.breakpoints.down('xs'))
    const matchesMD =  useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        async function fetchUrl(url) {
            let response = await fetch('api/film/count')
            let json = await response.json()
            setFilmCount(json.count)

            response = await fetch('/api/film')
            json = await response.json()
            console.log(json)
            setFilms(json)
            //console.log(json)
        }
        
        ReactGA.pageview(window.location.pathname + window.location.search);
        fetchUrl()
        
    }, [])

    const handleChange = (event) => {
        setSearchParam(event.target.value)
    }

    const filmListSorted = getVisibleFilms(films, searchParam)

    console.log(filmListSorted)

    return (
        <Grid>
            {/* ----- Header Block ----- */}
            <Grid item container className={classes.imageBackground}>
                <Grid container direction='column' justify='center' alignItems="center" style={{ paddingTop : matchesXS ? '3em' : '10em', paddingBottom : matchesXS ? '3em' : '10em' }}>
                    <Grid item className={classes.title}>
                        <Typography variant='h1'>Film</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ----- About Block ----- */}
            <Grid item container direction='column' style={{padding : '2em'}} alignItems='center' justify= 'center'>
                <Grid item style={{paddingTop: matchesMD ? '2em' : 0 }}>
                    <Typography variant='h4' align='center' paragraph gutterBottom>Here is the complete list of films that I have watched.</Typography>
                </Grid>
                <Grid item style={{paddingTop : '2em'}}>
                    <Typography  variant='h4' align='center' paragraph gutterBottom>Currently, I have watched...</Typography>
                    <Typography  variant='h4' align='center' paragraph gutterBottom><span style={{fontSize: '2.75em', fontStyle: 'bold'}}>{filmCount}</span></Typography>
                    <Typography  variant='h4' align='center' paragraph gutterBottom>...different films.</Typography>
                </Grid>
            </Grid>
            {/* ----- Search Block ----- */}
            <Grid item container direction='column' alignItems='center' justify= 'center'>
                <Input 
                    id='standard-adornment-search'
                    fullWidth
                    onChange={handleChange}
                    startAdornment={<InputAdornment position='start'><SearchIcon /></InputAdornment>}
                    style={{width : '30%'}}
                    placeholder='Search by Title'
                />
            </Grid>
            {/* ----- Table Block ----- */}
            <Grid item container direction='column' style={{padding : '2em'}} alignItems='center' justify= 'center'>
                <Table className={classes.table} size="small" stickyHeader >
                    <TableHead className={classes.head} >
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Title</TableCell>
                            <TableCell align='center'>Year</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {filmListSorted.map((film) => (
                        <TableRow key={film.id} onClick={() => window.open(film.imdb_url, "_blank")} style={{ textDecoration : 'none', cursor : 'pointer'}} hover>
                            <TableCell align="center">{film.id}</TableCell>
                            <TableCell align="center">{film.name}</TableCell>
                            <TableCell align="center">{film.year}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default Film
