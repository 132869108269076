import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const NotFound = () => {
    return (
        <Grid container direction='column' alignItems='center' justify='center'>
            <Grid item style={{ paddingTop : '7.5em', paddingLeft: '2em', paddingRight: '2em'}}>
                <Typography variant='h2' align='center'>Oops! That page can’t be found.</Typography>
            </Grid>
            <Grid item style={{ paddingTop : '12em', paddingBottom : '12em', paddingLeft: '2em', paddingRight: '2em'}}>
                <Typography variant='body1' align='center'>Maybe try one of the links in the menu or press back to go to the previous page.</Typography>
            </Grid>
        </Grid>
    )
}

export default NotFound