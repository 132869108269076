import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { useCookies } from 'react-cookie'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import axios from 'axios'
import { isExpired, decodeToken } from "react-jwt"

import headerImg from '../../assets/homepage/header.jpg'

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    root: {
        background : 'white'
    },
    imageBackground:{
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%'
    }
}))

const Login = () => {
    const classes = useStyles()
//    const theme = useTheme()

    //const matchesSM =  useMediaQuery(theme.breakpoints.down('sm'))

    const [cookies, ] = useCookies('khrishanWebsite');
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        backgroundColor: ''
    })
    const [, setCookie] = useCookies(['name'])
    const history = useHistory()

    const loginRequest = async () => {
        try {
            const dt = new Date()
            dt.setHours( dt.getHours() + 2 )

            const res = await axios.post('api/user/login', {
                username,
                password
            })
            const json = await res.data

            setCookie('kp_token', json.token, { path: '/', expires : dt });
            history.push('/finance')

        } catch (e){
            setAlert({
                open: true,
                message: 'Something went wrong, please try again!',
                backgroundColor: '#ff3232'
            })
        }
    }
    
    const checkAccessToken = () => {
        if(cookies['kp_token']){
            try {
                const token = cookies['kp_token']
                const myDecodedToken = decodeToken(token)
                if(myDecodedToken === null){
                    return false
                }
                return !isExpired(token)
            } catch (e) {
                return false
            }
        } else {
            return false
        }
    }

    if(checkAccessToken()){
        history.push('/finance')
    }

    return (
        <Grid container direction='column' alignItems='center' justify='center' className={classes.imageBackground} style={{paddingTop: '15em', paddingBottom: '15em'}}>
            <Grid item>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
            </Grid>
            <Grid item>
                <Typography component="h1" variant="h2" style={{color: 'white'}}>Sign in</Typography>
            </Grid>
            <Grid item container direction='column' alignItems='center' justify='center'>
                <Grid item>
                    <TextField
                        className={classes.root}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        required
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange = {(event) => setUsername(event.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        className={classes.root}
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange = {(event) => setPassword(event.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={loginRequest}
                    >Sign In
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={alert.open}
                message={alert.message}
                ContentProps={{style: {backgroundColor: alert.backgroundColor}}}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                onClose={() => setAlert({...alert, open: false})}
                autoHideDuration={4 * 1000}
            />
        </Grid>
    )
}

export default Login
