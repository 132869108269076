import { createMuiTheme } from '@material-ui/core/styles'

const kpGreen = '#556B13'
const kpMint = '#50504e'
const yellowGreen = '#9DBD51'
const screamingGreen ='#889E43'
const offGrey = '#50504e'


const theme = createMuiTheme({
    palette: {
        common: {
            green : `${kpGreen}`,
            screamingGreen: `${screamingGreen}`,
            mint: `${kpMint}`
        },
        primary: {
            main: `${kpGreen}`
        },
        secondary: {
            main: `${kpMint}`
        },
        third: {
            main: `${yellowGreen}`
        },
        fourth: {
            main: `${screamingGreen}`
        }
    },
    typography: {
        h1: {
            fontFamily: 'Raleway',
            fontWeight: 500,
            fontSize: '4rem',
            color: '#FFFFFF',
            textAlign: 'center',
        }, 
        h2: {
            fontFamily: 'Raleway',
            fontWeight: 700,
            fontSize: '2.5rem',
            color: kpGreen,
            lineHeight: 1.5
        }, 
        h3: {
            fontWeight: 200,
            fontSize: '2.5em',
            color: yellowGreen
        },
        h4: {
            fontWeight: 200,
            fontSize: '1.75em',
            color: yellowGreen
        },
        h5: {
            fontWeight: 200,
            fontSize: '1.75em',
            color: offGrey
        },
        tab: {
            fontColor: '#FFFFFF',
            fontFamily: 'Raleway',
            fontWeight: 700,
            fontSize: '1rem',
        },
        body1: {
            fontSize: '1.25rem',
            color: offGrey,
            fontWeight: 300
        }
    },
    overrides: {
        MuiInputLabel: {
          root: {
            color: kpGreen,
            fontSize: "1rem"
          }
        },
        MuiInput: {
          root: {
            color: offGrey,
            fontWeight: 300
          },
          underline: {
            "&:before": {
              borderBottom: `2px solid ${kpGreen}`
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
              borderBottom: `2px solid ${kpGreen}`
            }
          }
        },
        MuiFormControlLabel: {
          label : {
            color: kpGreen,
            fontWeight: 700
          },
          labelPlacementStart:{
              marginLeft : 0
          }
        },
        MuiTableCell: {
            root : {

            },
            head: {
                fontSize : '1rem',
                fontWeight: 700,
                color : kpGreen,
                borderColor: kpGreen
            }
        }
      }
})

export default theme
