import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Link } from 'react-router-dom'
import useMediaQuery from  '@material-ui/core/useMediaQuery'
import {useTheme} from '@material-ui/core/styles'
import MenuIcon from "@material-ui/icons/Menu"

import logo from '../../assets/kpsLogo.png'

const useStyles = makeStyles(theme => ({
    toolbarMargin: {
        ...theme.mixins.toolbar,
        marginBottom: '3em',
        [theme.breakpoints.down('md')]: {
            marginBottom: '2em'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1.25em',
        }
    },
    logo:{
        height: '7em',
        [theme.breakpoints.down('md')]: {
            height: '6em'
        },
        [theme.breakpoints.down('xs')]: {
            height: '3.5em'
        }
    },
    logoContainer: {
        '&:hover':{
            backgroundColor: 'transparent'
        }
    },
    tabContainer:{
        marginLeft: 'auto'
    },
    tab:{
        ...theme.typography.tab,
        minWidth: 10,
        marginLeft: '25px'
    },
    menu: {
        backgroundColor: theme.palette.common.green,
        color: '#151515'
    },
    menuItem:{
        ...theme.typography.tab,
        opacity: 0.7,
        '&:hover':{
            opacity: 1
        }
    },
    drawerIconContainer: {
        marginLeft: 'auto',
        '&:hover':{
            backgroundColor: 'transparent'
        }
    },
    drawerIcon: {
        height: '50px',
        width: '50px'
    },
    drawer:{
        backgroundColor: theme.palette.common.green
    },
    drawerItem:{
        ...theme.typography.tab,
        opacity: 0.7,
        color: '#151515'
    },
    drawerItemSelected:{
        '& .MuiListItemText-root':{
            opactity: 1
        }
    },
    appbar: {
        zIndex: theme.zIndex.modal + 1
    }
}))

const Navbar = (props) => {

    // Hooks
    const classes = useStyles()
    const theme = useTheme()
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const matches = useMediaQuery(theme.breakpoints.down('md'))

    const [value, setValue] = useState(5)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [openDrawer, setOpenDrawer] = useState(false)

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    const routes = [
        {name: 'Blog', link: '/blog', activeIndex: 0},
        {name: 'Bucket List', link: '/bucketlist', activeIndex: 1},
        {name: 'Scratchmap', link: '/scratchmap', activeIndex: 2}
    ]

    // Check current url and make sure its correcti
    useEffect(() => {
        [...routes].forEach((route) => {
            switch(window.location.pathname) {
                case `${route.link}`:
                    if(value !== route.activeIndex){
                        setValue(route.activeIndex)
                        if(route.selectedIndex && route.selectedIndex !== selectedIndex){
                            setSelectedIndex(route.selectedIndex)
                        }
                    }
                    break
                case '/':
                    setValue(5)
                    break
                default:
                    break
            }
        })
    }, [value, routes, selectedIndex])

    const tabs = (
        <React.Fragment>

            <Tabs value={value} onChange={handleChange} className={classes.tabContainer} scrollButtons='auto'>
                {routes.map((route, index) => (
                    <Tab
                        key={`${route}${index}`}
                        className={classes.tab}
                        component={Link}
                        to={route.link}
                        label={route.name}
                        aria-owns={route.ariaOwns}
                        aria-haspopup={route.ariaPopup}
                        onMouseOver={route.mouseOver}
                    />
                ))}
            </Tabs>
        </React.Fragment>
    )

    const drawer = (
        <React.Fragment>
            <SwipeableDrawer classes={{paper: classes.drawer}}anchor='right' disableBackdropTransition={!iOS} disableDiscovery={iOS} open={openDrawer} onClose={() => setOpenDrawer(false)} onOpen={() => setOpenDrawer(true)}>
            <div className={classes.toolbarMargin} />
            <List disablePadding>
                {routes.map(route => (
                    <ListItem key={`${route}${route.activeIndex}`} onClick={() => {setOpenDrawer(false); setValue(route.activeIndex)}} component={Link} to={route.link} divider button selected={value === route.activeIndex} classes={{selected: classes.drawerItemSelected}}>
                        <ListItemText className={classes.drawerItem} disableTypography>{route.name}</ListItemText>
                    </ListItem>
                ))}
            </List>
            </SwipeableDrawer>
            <IconButton className={classes.drawerIconContainer} onClick = {() => setOpenDrawer(!openDrawer)} disableRipple>
                <MenuIcon className={classes.drawerIcon}/>
            </IconButton>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <AppBar color='primary' className={classes.appbar}>
                <Grid container direction='row' justify='space-between' alignItems='center'>
                    <Grid item>
                        <Button onClick={() => setValue(5)} component={Link} to='/' className={classes.logoContainer} disableRipple>
                            <img src={logo} alt='company logo' className={classes.logo}/>
                        </Button>
                    </Grid>
                    <Grid item>
                        {matches  ? drawer : tabs}
                    </Grid>
                </Grid>
            </AppBar>
        </React.Fragment>
    )
}

export default Navbar
