import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'

import facebook from '../../assets/footer/facebook.svg'
import twitter from '../../assets/footer/twitter.svg'
import instagram from '../../assets/footer/instagram.svg' 
import github from '../../assets/footer/github.svg' 
import linkedin from '../../assets/footer/linkedin.svg' 

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  disclaimer: {
    fontFamily: 'Raleway',
    width:'80%'
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    textAlign: 'center',
    backgroundColor: '#151515',
    color: '#f4f5f7'
  },
  copyright:{
    width: '100%',
    color: '#f4f5f7',
    fontFamily: 'Raleway',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]:{
        fontSize: '0.9em'
    }
  },
  icon: {
    height: '2em',
    width: '2em',
    [theme.breakpoints.down('xs')]:{
        height: '1.5em',
        width: '1.5em',
    }
},
}));

export default function StickyFooter() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
        <footer className={classes.footer}>
            <Grid container direction="column" justify="space-around" alignItems="center" spacing={2}>
                <Hidden mdDown>
                    <Grid item className={classes.disclaimer} style={{padding:'2em'}}>
                        NOTE : This is a personal blog. It in no way reflects the views or plans of any potential employer.
                        This is a non-profit site and is not associated with or sponsored by any third parties.
                        All images that appear on this blog are copyright their respective owners and I claim no credit for them unless otherwise noted.
                    </Grid>
                </Hidden>
                <Grid container justify='center' className={classes.socialContainer} spacing={2}>
                    <Grid item component={'a'} href='https://www.facebook.com/khrishan94' rel='noopener noreferrer' target='_blank'>
                        <img alt='Facebook logo' src={facebook} className={classes.icon} />
                    </Grid>
                    <Grid item component={'a'} href='https://www.twitter.com/khrishan94' rel='noopener noreferrer' target='_blank'>
                        <img alt='Twitter logo' src={twitter}  className={classes.icon} />
                    </Grid>
                    <Grid item component={'a'} href='https://www.instagram.com/khrishan94' rel='noopener noreferrer' target='_blank'>
                        <img alt='Instagram logo' src={instagram}  className={classes.icon} />
                    </Grid>
                    <Grid item component={'a'} href='https://www.github.com/khrishan' rel='noopener noreferrer' target='_blank'>
                        <img alt='GitHub logo' src={github}  className={classes.icon} />
                    </Grid>
                    <Grid item component={'a'} href='https://www.linkedin.com/in/khrishanpatel/' rel='noopener noreferrer' target='_blank'>
                        <img alt='LinkedIn logo' src={linkedin}  className={classes.icon} />
                    </Grid>
                </Grid>
                <Grid item className={classes.copyright} style={{padding:'2em'}}>
                    {'© '}
                    {new Date().getFullYear()}
                    {' KPS'}
                </Grid>
            </Grid>
        </footer>
    </div>
  )
}