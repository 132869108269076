import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CrossIcon from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import config from "../../config.json";

import headerImg from "../../assets/countycricket/header.jpg";

const useStyles = makeStyles((theme) => ({
  imageBackground: {
    backgroundImage: `url(${headerImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "cover",
    paddingTop: "5em",
  },
  head: {
    backgroundColor: theme.palette.common.green,
    color: "#FFFFFF",
  },
  title: {},
  table: {
    [theme.breakpoints.down("xl")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "60%",
    },
  },
}));

ReactGA.initialize(config.googleTrackingID);

const CountyCricket = () => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const [venues, setVenues] = useState({});

  useEffect(() => {
    async function fetchUrl() {
      const url = `/api/public/countycricket/countycricket.json`;
      const response = await fetch(url);
      let text = await response.json();
      setVenues(text);
    }

    fetchUrl();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid>
      {/* ----- Header Block ----- */}
      <Grid item container className={classes.imageBackground}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{
            paddingTop: matchesXS ? "3em" : "10em",
            paddingBottom: matchesXS ? "3em" : "10em",
          }}
        >
          <Grid item className={classes.title}>
            <Typography variant="h1">
              Visiting County Cricket Grounds
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* ----- About Block ----- */}
      <Grid
        item
        container
        direction="column"
        style={{ padding: "2em" }}
        alignItems="center"
        justify="center"
      >
        <Grid item style={{ paddingTop: matchesMD ? "2em" : 0 }}>
          <Typography variant="h4" align="center" paragraph gutterBottom>
            So if you haven't noticed, I love cricket.
          </Typography>
        </Grid>
        <Grid item style={{ paddingTop: "2em" }}>
          <Typography variant="body1" align="center" paragraph gutterBottom>
            So much so, I have decided that my aim is to visit every county
            ground in the country.
          </Typography>
          <Typography variant="body1" align="center" paragraph gutterBottom>
            This can be for any kind of game (Domestic or International) - just
            so long as I have seen a match (or day's play) at said ground.
          </Typography>
          <Typography variant="body1" align="center" paragraph gutterBottom>
            As I visit each stadium, I shall update this table and provide
            evidence below the table.
          </Typography>
        </Grid>
      </Grid>

      {/* ----- Table Block ----- */}
      {Object.keys(venues).length === 0 ? (
        <Grid
          container
          align="center"
          justifyContent="center"
          style={{ padding: "1em" }}
        >
          <Grid item>
            <Typography variant="body1">No Data Found</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          direction="column"
          style={{ padding: "2em" }}
          alignItems="center"
          justify="center"
        >
          {Object.keys(venues).map((team) => {
            return (
              <Accordion
                style={{
                  width: "80%",
                  backgroundColor: venues[team].hasOwnProperty("visit")
                    ? "#90ee90"
                    : undefined,
                  border: expanded === team ? "1px solid" : undefined,
                }}
                square
                cursor="default"
                expanded={expanded === team}
                onChange={handleChange(team)}
              >
                <AccordionSummary>
                  <Grid container justifyContent="space-between" align="center">
                    <Grid item xs={4}>
                      <Typography align="left">
                        {team.charAt(0).toUpperCase() + team.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {venues[team].hasOwnProperty("visit") ? (
                        <CheckIcon />
                      ) : (
                        <CrossIcon />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{venues[team]["ground"]}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                {venues[team].hasOwnProperty("visit") ? (
                  <AccordionDetails style={{ backgroundColor: "#FFFFFF" }}>
                    <Grid item id={team}>
                      <Grid
                        container
                        direction="column"
                        style={{ paddingTop: matchesMD ? "2em" : 0 }}
                      >
                        <Grid item>
                          <Typography
                            variant="body1"
                            align="center"
                            paragraph
                            gutterBottom
                          >
                            Date : {venues[team]["visit"]["date"]}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {venues[team]["visit"]["post"]
                            .split("\n")
                            .map((text) => (
                              <Typography
                                variant="body1"
                                paragraph
                                gutterBottom
                              >
                                {text}
                              </Typography>
                            ))}
                        </Grid>
                        <Grid
                          item
                          style={{ paddingTop: "1em", paddingBottom: "1em" }}
                        >
                          <div align="center">
                            <img
                              src={`/api/public/countycricket/${venues[team]["visit"]["image"]}`}
                              width="75%"
                              alt={venues[team]["visit"]["alt"]}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                ) : undefined}
              </Accordion>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default CountyCricket;
